/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    InputGroup, Form, Row, Col, Button, ListGroup, Badge, Modal,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { Navigation } from '../components/Navigation/index';
import { today as TodayTime } from '../../../helpers/date';
import { Loading } from '../../../components/Loading';
import { $services } from '../../../services';
import { errorHandler } from '../../../services/helpers';
import { $store } from '../../../store';
import '../scss/dashboard.scss';

/**
 * Todo list implementation
 */
export const Todo: React.FC = () => {
    const [isLoading, setLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [roomList, setRoomList] = useState([]);
    const [todoList, setTodoList] = useState([]);
    const [deleteItem, setDeleteItem] = useState<any>({});
    const [model, setModel] = useState({
        description: '',
    });
    const navigate = useNavigate();

    /**
     * Fetch room list
     */
    const fetch = () => {
        setFetchLoading(true);
        $services.Room.list().then((response: any) => {
            setRoomList(response.data);
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setFetchLoading(false);
        });
    };

    /**
     * Fetch todo list
     */
    const fetchToDoList = () => {
        setLoading(true);
        $services.Todo.list().then((response: any) => {
            setTodoList(response.data);
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    /**
     * Delete task
     */
    const deleteTask = () => {
        setLoading(true);
        $services.Todo.delete({ id: deleteItem.id }).then(() => {
            toast.success('Your Task has been deleted.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setShowDeleteModal(false);
            setDeleteItem({});
            setTodoList((state: any) => state.filter((todo: any) => todo.id !== deleteItem.id));
            fetchToDoList();
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    /**
     * Submit shopping form
     */
    const submitForm = () => {
        setLoading(true);
        $services.Todo.create(model).then(() => {
            toast.success('Your Task has been added.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setModel({
                description: '',
            });
            fetchToDoList();
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        const { isLoggedIn } = $store.getState().auth;
        if (isLoggedIn) {
            fetch();
            fetchToDoList();
        } else {
            navigate('/auth/login');
        }
    }, []);

    return (
        <Loading active={ fetchLoading }>
            <div className="m-dashboard m-dashboard__todo">
                <Helmet>
                    <title>TODOs</title>
                </Helmet>

                <header>
                    <span className="today-time">{ TodayTime() }</span>

                    <h3 className="m-dashboard__title">TODOs</h3>
                </header>

                <Row className="m-dashboard__room-list">
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Room (optional):
                            </Form.Label>
                            <Form.Select
                                aria-label="Room select"
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        room_id: event.target.value,
                                    }));
                                } }
                            >
                                <option value="">
                                    Select room
                                </option>
                                {
                                    roomList.map((room: any) => (
                                        <option
                                            value={ room.id }
                                            key={ room.id }
                                        >
                                            { room.name }
                                        </option>
                                    ) )
                                }
                            </Form.Select>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Task description"
                                    value={ model.description || '' }
                                    onKeyUp={ (event: any) => {
                                        if (event.keyCode === 13) {
                                            submitForm();
                                        }
                                    } }
                                    onChange={ (event: any) => {
                                        setModel((state: any) => ({
                                            ...state,
                                            description: event.target.value,
                                        }));
                                    } }
                                />
                            </InputGroup>
                        </div>
                    </Col>

                    <Col md="12">
                        <Button
                            variant="primary"
                            disabled={ isLoading }
                            className="w-100"
                            onClick={ submitForm }
                        >
                            { isLoading ? 'Loading…' : 'Submit' }
                        </Button>

                        <Button
                            variant="warning"
                            className="w-100"
                            onClick={ () => {
                                setModel({
                                    description: '',
                                });
                            } }
                        >
                            Clear Form
                        </Button>
                    </Col>

                    <Col
                        md="12"
                        className="mt-5"
                    >
                        <p className="todo-list-title">Tasks</p>

                        { todoList.length ? (
                            <ListGroup>
                                {
                                    todoList.map((todoListItem: any) => (
                                        <ListGroup.Item
                                            className="d-flex justify-content-between align-items-center"
                                            key={ todoListItem.id }
                                            disabled={ isLoading }
                                        >
                                            <div className="ms-2 me-auto">
                                                { todoListItem.description }
                                            </div>
                                            <Badge
                                                bg="primary"
                                                pill
                                            >
                                                { get(roomList.find((room: any) => room.id === todoListItem.room_id), 'name') }
                                            </Badge>
                                            <Badge
                                                bg="danger"
                                                pill
                                                className="delete"
                                                onClick={ () => {
                                                    setDeleteItem(todoListItem);
                                                    setShowDeleteModal(true);
                                                } }
                                            >
                                                <i className="fa-solid fa-xmark" />
                                            </Badge>
                                        </ListGroup.Item>
                                    ))
                                }
                            </ListGroup>
                        ) : (
                            <Button
                                variant="light"
                                disabled
                                className="w-100"
                            >
                                Every thing is done 😀
                            </Button>
                        )}
                    </Col>
                </Row>

                {/* Delete modal */}
                <Modal
                    show={ showDeleteModal }
                    onHide={ () => setShowDeleteModal(false) }
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete task</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to delete
                        <span>
                            { ' ' }
                            (
                            { ' ' }
                            { deleteItem.description }
                            { ' ' }
                            )
                            { ' ' }
                        </span>
                        ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={ () => setShowDeleteModal(false) }
                            disabled={ isLoading }
                        >
                            Close
                        </Button>
                        <Button
                            variant="danger"
                            onClick={ () => {
                                deleteTask();
                            } }
                            disabled={ isLoading }
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Navigation />
            </div>
        </Loading>
    );
};
