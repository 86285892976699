/* eslint-disable camelcase */
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    InputGroup, Modal, Col, Row, Button, ListGroup, Badge,
} from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import Form from 'react-bootstrap/Form';
import { Calendar } from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import weekends from 'react-multi-date-picker/plugins/highlight_weekends';
import moment from 'jalali-moment';
import stc from 'string-to-color';
import { toast } from 'react-toastify';
import { toJpeg } from 'html-to-image';
import { $services } from '../../../services';
import { errorHandler } from '../../../services/helpers';
import { Navigation } from '../components/Navigation';
import { EditReservation } from '../components/EditReservation';
import { Loading } from '../../../components/Loading';
import { digits, formatPrice, separate } from '../../../helpers/number';
import { today as TodayTime } from '../../../helpers/date';
import { $store } from '../../../store';
import '../scss/dashboard.scss';

/**
 * Room single implementation
 */
export const RoomSingle: React.FC = () => {
    const [isLoading, setLoading] = useState(false);
    const [showEditExtraCostModal, setShowEditExtraCostModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showVoucherModal, setShowVoucherModal] = useState(false);
    const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [calendar, setCalendar] = useState<any>([]);
    const [extraCost, setExtraCost] = useState<any>([]);
    const [todoList, setTodoList] = useState([]);
    const [editItem, setEditItem] = useState<any>({});
    const [deleteTask, setDeleteTask] = useState<any>({});
    const [isDownloading, setDownloading] = useState(false);
    const [reserveDetail, setReserveDetail] = useState<any>({
        id                   : null,
        start_date           : null,
        end_date             : null,
        number_of_guest      : null,
        number_of_extra_guest: null,
        total_price          : null,
        payable_amount       : null,
        first_name           : null,
        last_name            : null,
        national_card        : null,
        phone_number         : null,
        email                : null,
        finalized            : null,
        residence_id         : null,
        room_id              : null,
    });
    const navigate = useNavigate();
    const param = useParams();

    const ref = useRef<HTMLDivElement>(null);
    const onButtonClick = useCallback(() => {
        setDownloading(true);
        if (ref.current === null) {
            toast.error('We can\'t download this image 😥.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setDownloading(false);
            return;
        }

        const refCur = ref.current;

        toJpeg(refCur, {
            backgroundColor: '#FFFFFF',
            type           : 'image/jpg',
            quality        : 1,
            cacheBust      : true,
        }).then(() => {
            toJpeg(refCur, {
                backgroundColor: '#FFFFFF',
                type           : 'image/jpg',
                quality        : 1,
                cacheBust      : true,
            }).then(() => {
                toJpeg(refCur, {
                    backgroundColor: '#FFFFFF',
                    type           : 'image/jpg',
                    quality        : 1,
                    cacheBust      : true,
                }).then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = `${reserveDetail.first_name ? `${reserveDetail.first_name}-` : ''}${reserveDetail.last_name ? `${reserveDetail.last_name}-` : ''}voucher.jpg`;
                    link.href = dataUrl;
                    setTimeout(() => {
                        link.click();
                        setDownloading(false);
                    }, 500);
                });
            });
        }).catch(() => {
            toast.error('We can\'t download this image 😥.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setDownloading(false);
        });
    }, [ref]);

    const rules = [
        {
            icon   : 'fa-solid fa-check',
            content: 'ساعت تحویل اتاق ۲ بعد از ظهر و تخلیه ۱۲ ظهر می‌باشد.',
        },
        {
            icon   : 'fa-solid fa-check',
            content: 'ساعت سرو صبحانه ۸ تا ۱۰ صبح می‌باشد. خارج از این بازه زمانی صبحانه شامل هزینه برای شما مهمان عزیز می‌گردد.',
        },
        {
            icon   : 'fa-solid fa-plus',
            content: 'خسارت زدن به اموال اقامتگاه شامل جریمه می‌باشد.',
        },
        {
            icon   : 'fa-solid fa-plus',
            content: 'استعمال دخانیات در اتاق ممنوعه و شامل جریمه ‌می‌باشد.',
        },
        {
            icon   : null,
            content: '<strong>قوانین کنسلی</strong>',
        },
        {
            icon   : 'fa-solid fa-exclamation',
            content: 'تا ۱۵ روز قبل از زمان رزرو هزینه به صورت کامل عودت داده می‌شود.',
        },
        {
            icon   : 'fa-solid fa-exclamation',
            content: '۱۴ تا ۷ روز قبل از زمان رزرو ۳۰٪ مبلغ کل کسر می‌گردد .',
        },
        {
            icon   : 'fa-solid fa-exclamation',
            content: '۷ تا ۳ روز قبل از زمان رزرو ۵۰٪ مبلغ کل کسر می‌گردد .',
        },
        {
            icon   : 'fa-solid fa-exclamation',
            content: 'کنسلی اتاق ۳ روز قبل از تاریخ ورود شامل پرداخت هیچ مبلغی به شما مهمان گرامی نمی‌باشد.',
        },
    ];

    /**
     * Format value of reserve detail
     * 
     * @param key - key of reservation details
     * @param value - value of reservation details
     * @returns {*}
     */
    const formatValue = (key: any, value: any): any => {
        if (key === 'finalized') {
            return value ? <i className="fa-regular fa-circle-check" /> : <i className="fa-regular fa-circle-xmark" />;
        }

        if (['payable_amount', 'total_price'].includes(key) && value) {
            if (key === 'payable_amount') {
                return `${separate(reserveDetail.total_price - reserveDetail.payable_amount)} T`;
            }
            return `${separate(value)} T`;
        }

        if (key === 'phone_number') {
            return <a href={ `tel:${value}` }>{ value }</a>;
        }

        return value || '-';
    };

    /**
     * Fetch todo list
     */
    const fetchToDoList = () => {
        setUpdateLoading(true);
        $services.Todo.list({
            room_id: param.id,
        }).then((response: any) => {
            setTodoList(response.data);
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setUpdateLoading(false);
        });
    };

    /**
     * Delete task
     */
    const doDeleteTask = () => {
        setUpdateLoading(true);
        $services.Todo.delete({ id: deleteTask.id }).then(() => {
            toast.success('Your Task has been deleted.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setShowDeleteTaskModal(false);
            setDeleteTask({});
            setTodoList((state: any) => state.filter((todo: any) => todo.id !== deleteTask.id));
            fetchToDoList();
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setUpdateLoading(false);
        });
    };

    /**
     * Get Reservation Details
     */
    const getReservationDetails = (id: string) => {
        setUpdateLoading(true);
        return $services.Reservation.detail({
            reservation_id: id,
        }).then((response: any) => {
            setReserveDetail(response.data);
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setUpdateLoading(false);
        });
    };

    /**
     * Get Extra cost list
     */
    const getExtraCostList = (id: string) => {
        setUpdateLoading(true);
        return $services.ExtraCost.list({
            reservation_id: id,
        }).then((response: any) => {
            setExtraCost(response.data);
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setUpdateLoading(false);
        });
    };

    /**
     * Submit Edit Extra Cost
     */
    const submitEditExtraCost = () => {
        setUpdateLoading(true);
        $services.ExtraCost.edit({
            ...editItem,
            cost: editItem.cost.toString().replace(/,/g, ''),
        }).then(() => {
            toast.success('Change item has been successful.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setEditItem({});
            setShowEditExtraCostModal(false);
            getExtraCostList(editItem.reservation_id);
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setUpdateLoading(false);
        });
    };

    /**
     * Submit Delete Extra Cost
     */
    const deleteExtraCost = () => {
        setUpdateLoading(true);
        $services.ExtraCost.delete({ id: editItem.id }).then(() => {
            toast.success('Delete item has been successful.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            getExtraCostList(editItem.reservation_id);
            setEditItem({});
            setShowDeleteModal(false);
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setUpdateLoading(false);
        });
    };

    /**
     * Fetch Calendar date
     */
    const fetchCalendarDates = (
        month: string|number = moment().locale('fa').format('M'),
        year: string|number = moment().locale('fa').format('YYYY'),
    ) => {
        setUpdateLoading(true);
        $services.Calendar.list({
            room_id: param.id,
            month,
            year,
        }).then((response: any) => {
            setCalendar(response.data);
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setUpdateLoading(false);
            setLoading(false);
        });
    };

    /**
     * Submit cancel reservation
     */
    const cancelReservation = () => {
        setUpdateLoading(true);
        $services.Reservation.delete({ id: reserveDetail.id }).then(() => {
            toast.success('Delete item has been successful.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setReserveDetail({
                id                   : null,
                start_date           : null,
                end_date             : null,
                number_of_guest      : null,
                number_of_extra_guest: null,
                total_price          : null,
                payable_amount       : null,
                first_name           : null,
                last_name            : null,
                national_card        : null,
                phone_number         : null,
                email                : null,
                finalized            : null,
                residence_id         : null,
                room_id              : null,
            });
            setShowCancelModal(false);
            setExtraCost([]);
            fetchCalendarDates();
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setUpdateLoading(false);
        });
    };

    /**
     * Returns voucher card
     * 
     * @returns {*}
     */
    const voucherCard = () => (
        <div className="voucher">
            <img
                src="/images/mahragh.jpg"
                alt="Mahragh"
            />

            <h6 className="voucher__room-title">
                <strong>{ JSON.parse(decodeURIComponent(param.name as string)).name }</strong>
                {' '}
                <small>اتاق</small>
            </h6>

            <div className="guest">
                <div className="guest-info-item">
                    <p className="guest-info-item__key">
                        نام:
                    </p>
                    <p className="guest-info-item__value">
                        { `${reserveDetail.first_name} ${reserveDetail.last_name}` }
                    </p>
                </div>

                <div className="guest-info-item">
                    <p className="guest-info-item__key">
                        کد ملی:
                    </p>
                    <p className="guest-info-item__value">
                        { digits(reserveDetail.national_code || '-', 'fa') }
                    </p>
                </div>

                <div className="guest-info-item">
                    <p className="guest-info-item__key">
                        تعداد نفرات:
                    </p>
                    <p className="guest-info-item__value">
                        { digits(`${reserveDetail.number_of_guest} + (${reserveDetail.number_of_extra_guest})`, 'fa') }
                    </p>
                </div>

                <div className="guest-info-item">
                    <p className="guest-info-item__key">
                        تاریخ اقامت:
                    </p>
                    <p
                        className="guest-info-item__value"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: digits(`از ${
                                moment(reserveDetail.start_date, 'jYYYY-jMM-jDD').locale('fa').format('dddd jMM/jDD')
                            } <br /> تا ${
                                moment(reserveDetail.end_date, 'jYYYY-jMM-jDD').locale('fa').format('dddd jMM/jDD')
                            } <br /> به مدت ${
                                reserveDetail.duration + 1
                            } شب`, 'fa') as string,
                        }}
                    />
                </div>

                <div className="guest-info-item">
                    <p className="guest-info-item__key">
                        شماره تماس:
                    </p>
                    <p className="guest-info-item__value">
                        { digits(reserveDetail.phone_number || '-', 'fa') }
                    </p>
                </div>

                <div className="guest-info-item">
                    <p className="guest-info-item__key">
                        مبلغ کل:
                    </p>
                    <p className="guest-info-item__value">
                        { `${digits(separate(reserveDetail.total_price || '-'), 'fa')} تومان` }
                    </p>
                </div>
            </div>
            <hr className="divider" />

            <div className="host">
                <div className="host-info-item">
                    <p className="host-info-item__key">
                        شماره تماس میزبان:
                    </p>
                    <p className="host-info-item__value">
                        { digits('0939 761 42 48', 'fa') }
                    </p>
                </div>

                <ul className="description">
                    { rules.map((rule) => (
                        <li key={ rule.content }>
                            { !!rule.icon && <i className={ rule.icon } /> }
                            {/* eslint-disable-next-line react/no-danger */}
                            <p dangerouslySetInnerHTML={{ __html: digits(rule.content, 'fa') as string }} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );

    useEffect(() => {
        const { isLoggedIn } = $store.getState().auth;
        if (isLoggedIn) {
            setLoading(true);
            fetchCalendarDates();
            fetchToDoList();
        } else {
            navigate('/auth/login');
        }
    }, []);

    return (
        <Loading active={ isLoading }>
            <div className="m-dashboard m-dashboard__room-single">
                <Helmet>
                    <title>Room Single</title>
                </Helmet>

                <header>
                    <span className="today-time">{ TodayTime() }</span>

                    <h3 className="m-dashboard__title">
                        { JSON.parse(decodeURIComponent(param.name as string)).name }
                    </h3>
                </header>

                <Row>
                    <Col lg="5" className="m-dashboard__room-single__calendar">
                        <Calendar
                            className={ `${updateLoading ? 'loading' : ''}` }
                            calendar={ persian }
                            plugins={ [weekends()] }
                            locale={ persian_fa }
                            disabled={ updateLoading }
                            shadow={ false }
                            onMonthChange={ ({ month, year }) => {
                                fetchCalendarDates(month.number, year);
                            } }
                            mapDays={ ({
                                date, today, isSameDate, selectedDate,
                            }: any) => {
                                const props: any = {};

                                if (isSameDate(date, selectedDate)) {
                                    props.style = {
                                        ...props.style,
                                        backgroundColor: '#FFFFFF',
                                        color          : '#424242',
                                    };
                                }

                                if (date.dayOfBeginning < today.dayOfBeginning) {
                                    props.style = {
                                        ...props.style,
                                        textDecorationLine: 'line-through',
                                        color             : '#999999',
                                        backgroundImage   : 'repeating-linear-gradient(-45deg, rgba(255,255,255, 0.25), rgba(255,255,255, 0.25) 1px, transparent 1px, transparent 6px)',
                                        backgroundSize    : '8px 8px',
                                        backgroundColor   : '#DDDDDD',
                                    };
                                }

                                const reservationId = calendar.find((d: any) => d.date === date.day)?.reservation_id;
                                if (reservationId) {
                                    props.onClick = () => {
                                        getReservationDetails(reservationId);
                                        getExtraCostList(reservationId);
                                    };
                                    props.style = {
                                        ...props.style,
                                        backgroundColor: date.dayOfBeginning < today.dayOfBeginning ? '#DDDDDD' : 'rgba(179, 179, 179, 0.2)',
                                        color          : isSameDate(date, selectedDate) ? '#424242' : '#999999',
                                        border         : `2px dashed ${stc(reservationId)}`,
                                        cursor         : 'pointer',
                                    };
                                } else {
                                    props.onClick = () => {
                                        setReserveDetail({
                                            id                   : null,
                                            start_date           : null,
                                            end_date             : null,
                                            number_of_guest      : null,
                                            number_of_extra_guest: null,
                                            total_price          : null,
                                            payable_amount       : null,
                                            first_name           : null,
                                            last_name            : null,
                                            national_card        : null,
                                            phone_number         : null,
                                            email                : null,
                                            finalized            : null,
                                            residence_id         : null,
                                            room_id              : null,
                                        });
                                        setExtraCost([]);
                                    };
                                }

                                return {
                                    ...props,
                                    children: (
                                        <div>
                                            <div>{ date.format('D') }</div>
                                            <div style={{
                                                fontSize  : '8px',
                                                textAlign : 'center',
                                                fontWeight: '400',
                                            }}
                                            >
                                                { isSameDate(date, today) ? 'امروز' : '' }
                                            </div>
                                        </div>
                                    ),
                                };
                            } }
                        />

                        <div className="ticketContainer">
                            <div className="ticket">
                                <div className="ticketTitle">
                                    <i className="fa-regular fa-credit-card" />
                                    Extra Cost
                                </div>
                                <div className="ticketRip">
                                    <div className="circleLeft" />
                                    <div className="ripLine" />
                                    <div className="circleRight" />
                                </div>
                                <div className="ticketDetail">
                                    <Col md="12">
                                        <div className="extra-cost__item header">
                                            <span className="extra-cost__item__key">
                                                Item :
                                            </span>
                                            <span className="extra-cost__item__value">
                                                cost (T)

                                                <sub>/day</sub>
                                            </span>
                                        </div>
                                    </Col>
                                    {
                                        extraCost.map((item: any) => (
                                            <Col
                                                md="12"
                                                key={ item.id }
                                            >
                                                <div className={ `extra-cost__item ${item.paid ? 'paid' : 'not-paid'}` }>
                                                    <span className="extra-cost__item__key">
                                                        <span className="actions">
                                                            <i
                                                                className="fa-solid fa-pencil edit"
                                                                title="Edit"
                                                                aria-hidden
                                                                onClick={ () => {
                                                                    setEditItem(item);
                                                                    setShowEditExtraCostModal(true);
                                                                } }
                                                            />
                                                            <i
                                                                className="fa-solid fa-trash-can remove"
                                                                title="Remove"
                                                                aria-hidden
                                                                onClick={ () => {
                                                                    setEditItem(item);
                                                                    setShowDeleteModal(true);
                                                                } }
                                                            />
                                                        </span>

                                                        { item.type }
                                                    </span>
                                                    <span className="extra-cost__item__value">
                                                        { separate(item.cost) }

                                                        <sub>
                                                            /
                                                            {item.date.split('-').at(-1)}
                                                        </sub>
                                                    </span>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </div>
                                <div className="ticketRip">
                                    <div className="circleLeft" />
                                    <div className="ripLine" />
                                    <div className="circleRight" />
                                </div>
                                <div className="ticketSubDetail">
                                    <div className="code">Total:</div>
                                    <div className="date">
                                        { separate(extraCost.filter((item: any) => !item.paid).reduce((accumulator: any, currentValue: any) => accumulator + currentValue.cost, 0)) }

                                        <span>Toman</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="7">
                        <Row className="reservation-details__list">
                            <p className="reservation-details__title">
                                <Button
                                    className="reservation-details__actions edit"
                                    variant="light"
                                    disabled={ !reserveDetail.id }
                                    onClick={ () => setShowEditModal(true) }
                                >
                                    <i
                                        className="fa-solid fa-pencil"
                                        title="Edit"
                                    />
                                    Edit
                                </Button>

                                <i className="fa-solid fa-file-invoice-dollar" />
                                <span>Invoice</span>

                                <Button
                                    className="reservation-details__actions remove"
                                    variant="light"
                                    disabled={ !reserveDetail.id }
                                    onClick={ () => setShowCancelModal(true) }
                                >
                                    <i
                                        className="fa-solid fa-ban"
                                        title="Remove"
                                    />
                                    Cancel
                                </Button>
                            </p>

                            { Object.entries(reserveDetail).map(([key, value]: any) => {
                                if (!['id', 'room_id', 'residence_id', 'removed_at', 'national_card', 'email'].includes(key)) {
                                    return (
                                        <Col
                                            md="12"
                                            key={ key }
                                        >
                                            <div className="reservation-details__item">
                                                <span className="reservation-details__item__key">
                                                    { key.replaceAll('_', ' ') }
                                                    :
                                                </span>
                                                <span className="reservation-details__item__value">
                                                    { formatValue(key, value) }
                                                </span>
                                            </div>
                                        </Col>
                                    );
                                }

                                return '';
                            } ) }
                        </Row>
                    </Col>

                    <Col md="12">
                        <Button
                            variant="light"
                            disabled
                            className="cost w-100 mt-5"
                        >
                            <span>
                                مبلغ قابل پرداخت برای تسویه حساب:
                                {' '}
                                {' '}
                                {
                                    formatPrice((reserveDetail.total_price - reserveDetail.payable_amount)
                                + extraCost.filter((item: any) => !item.paid).reduce((accumulator: any, currentValue: any) => accumulator + currentValue.cost, 0))
                                }
                            </span>
                        </Button>
                    </Col>

                    { reserveDetail.start_date && (
                        <>
                            <Col md="12">
                                <Button
                                    variant="outline-info"
                                    className="w-100 mb-2"
                                    disabled={ isDownloading }
                                    onClick={ () => setShowVoucherModal(true) }
                                >
                                    Full view to take screen shot
                                </Button>
                            </Col>
                            <Col md="12">
                                <Button
                                    variant="outline-primary"
                                    className="w-100"
                                    disabled={ isDownloading }
                                    onClick={ () => onButtonClick() }
                                >
                                    { isDownloading ? 'Loading…' : 'Download voucher' }
                                </Button>
                            </Col>

                            <Col
                                md="12"
                                ref={ ref }
                            >
                                { voucherCard() }
                            </Col>
                        </>
                    ) }

                    { (todoList && !!todoList.length) && (
                        <Col
                            md="12"
                            className="mt-5 mb-3"
                        >
                            <p className="todo-list-title">TODOs</p>
                            <ListGroup>
                                {
                                    todoList.map((todoListItem: any) => (
                                        <ListGroup.Item
                                            className="d-flex justify-content-between align-items-center"
                                            key={ todoListItem.id }
                                            disabled={ updateLoading }
                                        >
                                            <div className="ms-2 me-auto">
                                                { todoListItem.description }
                                            </div>
                                            <Badge
                                                bg="danger"
                                                pill
                                                className="delete"
                                                onClick={ () => {
                                                    setDeleteTask(todoListItem);
                                                    setShowDeleteTaskModal(true);
                                                } }
                                            >
                                                <i className="fa-solid fa-xmark" />
                                            </Badge>
                                        </ListGroup.Item>
                                    ))
                                }
                            </ListGroup>
                        </Col>
                    ) }
                </Row>

                {/* Edit Extra cost modal */}
                <Modal
                    show={ showEditExtraCostModal }
                    onHide={ () => setShowEditExtraCostModal(false) }
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit extra cost item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup>
                            <InputGroup.Text>تومان</InputGroup.Text>
                            <NumericFormat
                                className="form-control"
                                thousandSeparator=","
                                allowLeadingZeros
                                disabled={ updateLoading }
                                value={ editItem.cost || '' }
                                placeholder="Price"
                                onChange={ (event: any) => {
                                    setEditItem((state: any) => ({
                                        ...state,
                                        cost: event.target.value,
                                    }));
                                } }
                            />
                            <Form.Control
                                type="text"
                                placeholder="Item name"
                                disabled={ updateLoading }
                                value={ editItem.type || '' }
                                onChange={ (event: any) => {
                                    setEditItem((state: any) => ({
                                        ...state,
                                        type: event.target.value,
                                    }));
                                } }
                            />
                        </InputGroup>
                        <InputGroup>
                            <Form.Check
                                className="mt-2"
                                label="Is this Paid?"
                                id="paid-checkbox"
                                disabled={ updateLoading }
                                checked={ editItem.paid || '' }
                                onChange={ (event: any) => {
                                    setEditItem((state: any) => ({
                                        ...state,
                                        paid: event.target.checked,
                                    }));
                                } }
                            />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={ () => setShowEditExtraCostModal(false) }
                            disabled={ updateLoading }
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={ submitEditExtraCost }
                            disabled={ updateLoading }
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Delete modal */}
                <Modal
                    show={ showDeleteTaskModal }
                    onHide={ () => setShowDeleteTaskModal(false) }
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete task</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to delete
                        <span>
                            { ' ' }
                            (
                            { ' ' }
                            { deleteTask.description }
                            { ' ' }
                            )
                            { ' ' }
                        </span>
                        ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={ () => setShowDeleteTaskModal(false) }
                            disabled={ updateLoading }
                        >
                            Close
                        </Button>
                        <Button
                            variant="danger"
                            onClick={ () => {
                                doDeleteTask();
                            } }
                            disabled={ updateLoading }
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Delete modal */}
                <Modal
                    show={ showDeleteModal }
                    onHide={ () => setShowDeleteModal(false) }
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete extra cost item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to delete this extra cost item:
                        <br />
                        <br />
                        <strong>
                            { editItem.type }
                        </strong>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={ () => setShowDeleteModal(false) }
                            disabled={ updateLoading }
                        >
                            Close
                        </Button>
                        <Button
                            variant="danger"
                            onClick={ deleteExtraCost }
                            disabled={ updateLoading }
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Voucher modal */}
                <Modal
                    show={ showVoucherModal }
                    onHide={ () => setShowVoucherModal(false) }
                    centered
                    fullscreen
                    className="voucher-modal"
                >
                    <Modal.Header closeButton />
                    <Modal.Body>
                        { voucherCard() }
                    </Modal.Body>
                </Modal>

                {/* Cancel reservation modal */}
                { reserveDetail.id && (
                    <Modal
                        show={ showCancelModal }
                        onHide={ () => setShowCancelModal(false) }
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Cancel Reserve</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to Cancel this reserve?
                            <br />
                            <br />
                            <strong>
                                { `${reserveDetail.first_name} ${reserveDetail.last_name}` }
                            </strong>
                            <br />
                            <br />
                            <div>
                                { `Start Date: ${reserveDetail.start_date} / End Date: ${reserveDetail.end_date}` }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={ () => setShowCancelModal(false) }
                                disabled={ updateLoading }
                            >
                                No
                            </Button>
                            <Button
                                variant="danger"
                                onClick={ cancelReservation }
                                disabled={ updateLoading }
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}

                { reserveDetail.id && (
                    <EditReservation
                        open={ showEditModal }
                        close={ () => {
                            setShowEditModal(false);
                            getReservationDetails(reserveDetail.id);
                            getExtraCostList(reserveDetail.id);
                            fetchCalendarDates();
                        } }
                        content={ reserveDetail }
                        pricesResource={{
                            per_person: JSON.parse(decodeURIComponent(param.name as string)).cost,
                            additions : JSON.parse(decodeURIComponent(param.name as string)).additional_guest_cost,
                        }}
                    />
                ) }

                <Navigation />
            </div>
        </Loading>
    );
};
