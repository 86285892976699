import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { RoomCard } from '../components/RoomCard';
import { Navigation } from '../components/Navigation';
import { Loading } from '../../../components/Loading';
import { today as TodayTime } from '../../../helpers/date';
import { $services } from '../../../services';
import { errorHandler } from '../../../services/helpers';
import { $store } from '../../../store';
import '../scss/dashboard.scss';

/**
 * Dashboard implementation
 */
export const Dashboard: React.FC = () => {
    const [isLoading, setLoading] = useState(false);
    const [roomList, setRoomList] = useState([]);
    const navigate = useNavigate();

    /**
     * Fetch room list
     */
    const fetch = () => {
        setLoading(true);
        $services.Room.list().then((response: any) => {
            setRoomList(response.data);
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        const { isLoggedIn } = $store.getState().auth;
        if (isLoggedIn) {
            fetch();
        } else {
            navigate('/auth/login');
        }
    }, []);

    /**
     * Logout
     */
    const logout = () => {
        $store.dispatch({ type: 'auth/logout' });
        navigate('/auth/login');
    };

    return (
        <Loading active={ isLoading }>
            <div className="m-dashboard m-dashboard__main-page">
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>

                <header>
                    <span className="today-time">{ TodayTime() }</span>

                    <h3 className="m-dashboard__title">اتاق‌های قشنگ و زیبا 🏕️</h3>

                    <Button
                        variant="outline-danger"
                        onClick={ logout }
                    >
                        Logout
                    </Button>
                </header>

                <Row className="m-dashboard__room-list">
                    {
                        roomList.map((room: any) => (
                            <Col
                                key={ room.id }
                                md="3"
                            >
                                <RoomCard content={ room } />
                            </Col>
                        ))
                    }
                </Row>

                <Navigation />
            </div>
        </Loading>
    );
};
